import {
  FunctionComponent,
  useState,
  useCallback,
  ChangeEvent,
  FormEvent,
} from "react"
import Input from "../layout/form/input"
import Textarea from "../layout/form/textarea"
import { useUser, getCachedSecret } from "../../hooks/use-user"
import SubmitButton from "../layout/form/submit-button"
import React from "react"

export type Props = {
  onAdd: (account: string, title: string, description: string) => void
}

const AddModerator: FunctionComponent<Props> = ({ onAdd }) => {
  const [account, setAccount] = useState("")
  const onAccountChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setAccount(event.currentTarget.value)
    },
    []
  )

  const [title, setTitle] = useState("")
  const onTitleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setTitle(event.currentTarget.value)
  }, [])

  const [description, setDescription] = useState("")
  const onDescriptionChange = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement>) => {
      setDescription(event.currentTarget.value)
    },
    []
  )

  const { user, updateBalance } = useUser()
  const [isLoading, setLoading] = useState(false)
  const onSubmit = useCallback(
    async (event: FormEvent) => {
      event.preventDefault()
      console.log("onSubmit " + account)
      onAdd(account, title, description)
    },
    [user?.id, account, title, description, onAdd]
  )

  return (
    <section>
      <h1>Add moderator</h1>

      <form onSubmit={onSubmit}>
        <div>
          <h2>Account</h2>
          <Input value={account} onChange={onAccountChange} />
        </div>

        <div>
          <h2>Title</h2>
          <Input value={title} onChange={onTitleChange} />
        </div>

        <div>
          <h2>Description</h2>
          <Textarea
            value={description}
            onChange={onDescriptionChange}
            rows={6}
          />
        </div>

        <footer>
          <SubmitButton
            disabled={isLoading /* || !isValid*/}
            loading={isLoading}
          >
            Submit
          </SubmitButton>
        </footer>
      </form>

      <style jsx>{`
        section {
          padding: 1em 1em 0.5em;
        }
        form > *:not(:first-child) {
          margin-top: 1.2em;
        }
        h2 {
          font-size: inherit;
        }
        h3 {
          font-size: inherit;
          font-weight: lighter;
          margin: 0;
          margin-right: 1em;
        }
        .misc {
          display: flex;
        }
        .misc > * {
          flex: 1;
          display: flex;
          align-items: center;
        }
        .misc > *:not(:first-child) {
          margin-left: 1em;
        }
        footer {
          text-align: right;
        }
      `}</style>
    </section>
  )
}

export default AddModerator
