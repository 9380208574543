import { FunctionComponent } from "react"
import { Moderator } from "../../types"
import { lightTheme, darkTheme } from "../../services/theme"
import { exactDate } from "../../services/date"
import { prettyBalance } from "../../services/number"
import { useStyle } from "../../hooks/use-style"
import React from "react"
import Button from "../layout/button"

type Props = {
  moderator: Moderator
  onDelete?: (id: string) => void
}

const ModeratorPreview: FunctionComponent<Props> = ({
  moderator,
  onDelete,
}) => {
  const css = useStyle(theme => ({
    backgroundColor:
      theme === "light"
        ? lightTheme.color.background.primary
        : darkTheme.color.background.secondary,
    borderColor:
      theme === "light" ? lightTheme.color.border : darkTheme.color.border,
    infoColor:
      theme === "light"
        ? lightTheme.color.text.secondary
        : darkTheme.color.text.secondary,
    tagColor:
      theme === "light" ? lightTheme.color.active : darkTheme.color.active,
  }))

  return (
    <div className="element">
      <div className="table" style={{ width: "100%" }}>
        <div
          className="table-cell"
          style={{ width: "100%", display: "inline-table" }}
        >
          <div className="table" style={{ marginLeft: "10px" }}>
            <div className="table-row title">{moderator.title}</div>
            <div className="table-row" style={{ fontSize: "12px" }}>
              <div className="description">{moderator.description}</div>
            </div>
          </div>
          <div className="table-cell" style={{ width: "100px" }}>
            {exactDate(moderator.ts)}
          </div>
          <div
            className="table-cell"
            style={{ width: "100px", textAlign: "right" }}
          >
            $ {prettyBalance(moderator.amount)}
          </div>

          {onDelete != undefined && (
            <div
              className="table-cell"
              style={{
                textAlign: "right",
                paddingRight: "5px",
                width: "100px",
              }}
            >
              <Button onClick={() => onDelete(moderator.id)}>Remove</Button>
            </div>
          )}
        </div>
      </div>

      <style jsx>{`
                    .title {
                        font-size: 14px;
                        font-family: Roboto;
                        font-weight: 400;
                    }
                  .description {
                    padding-top: 5px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2; /* number of lines to show */
                    -webkit-box-orient: vertical;
                  }
                  .table {
                      display: table;
                  }
                  .table-cell {
                      display: table-cell;
                  }
                  .table-row {
                      display: table-row;
                  }
                  .element {
                      padding: 10px;
                      background-color: ${css.backgroundColor};
                      width: 100%;
                      margin-bottom: 10px;
                  }
                    article {
                        /*padding: 1.5em;*/
                        /*padding-top: 20px;*/
                        /*border-radius: 0.3em;*/
                        background-color: ${css.backgroundColor};
                        /*border: 1px solid ${css.borderColor};*/
                        transition: 200ms background-color, 200ms border-color;
                        position: relative;
                        z-index: 1;
                    }
                  img {
                      position: absolute;
                      top: -9999px;
                      left: -9999px;
                      right: -9999px;
                      bottom: -9999px;
                      margin: auto;
                      opacity: 0;
                  }
                    .interactable {
                        position: relative;
                        z-index: 1;
                    }
                    .label {
                        min-width: 0;
                    }
                    .image {
                        /*width: 40%;
                        position: absolute;
                        left: -33.333333%;
                        top: 1.5em;
                        overflow: hidden;*/
                        border-radius: 0;
                    }
                    header {
                        display: flex;
                        font-size: 0.8em;
                        overflow: hidden;
                    }
                    header > *:not(:first-child) {
                        margin-left: 1.6em;
                    }
                    h1,
                    .description {
                        margin: 0;
                    }
                    h1 {
                        font-size: 22px;
                        font-weight: 500;
                        margin-bottom: 0.5em;
                    }
                    p {
                        margin: 0;
                        font-size: 0.9em;
                        color: ${css.infoColor};
                        transition: 200ms color;
                    }
                    p:not(:first-child)::before {
                        content: "•";
                        margin-left: -0.5em;
                        padding-right: 0.5em;
                    }
                    .link {
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        font-size: 0;
                    }
                    .featured-comment {
                        transform: translateY(-2em);
                        margin-bottom: -2em;
                        margin-left: 10%;
                        margin-right: 20%;
                    }
                    button {
                        border-radius: 50%;
                        height: 2em;
                        width: 2em;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border: none;
                        background-color: transparent;
                        color: inherit;
                        transition: 200ms background-color;
                        z-index: 1;
                    }
                    button:disabled {
                        opacity: 0.5;
                    }
                    button:not(:disabled):hover {
                        background-color: ${css.backgroundColor};
                    }
                    .action-icon {
                        height: 100px;
                        width: 100px;
                        color: red;
                    }
                    .tag {
                      background-color: rgb(105, 105, 105, 0.1);
                      padding:5px;
                      margin-left:2px;
                      margin-right:2px;
                      border-radius: 5px;
                      font-size: 12px;
                      user-select: none;
                    }
                    .tag:hover {
                      background-color: rgb(105, 105, 105, 0.3);
                      cursor: pointer;
                    }
                    .actions {
                      display: table-row;
                      font-size: 14px;
                      color: #111111;
                      font-weight: 400;
                    }
                    .comments_count {
                      vertical-align: middle;
                      display: table-cell;
                      padding-right: 5px;
                    }
                    .comments {
                      display: table-cell;
                    }
                    .like {
                      display: table-cell;
                      vertical-align: middle;
                      margin-left: 15px;
                      font-size: 14px;
                    }
                    .table { 
                      display: table;
                   }
                   .table-row {display: table-row; }
                   .table-cell {display: table-cell;
                    vertical-align: middle; }
                    .comment-bubble {
                      font-size: 16px;
                      padding-left:5px;
                      padding-right:10px;
                    }
                    .amount {
                      display: table-cell;
                      vertical-align: middle;
                      font-size: 14px;
                      padding-right: 5px;
                    }
                    .link {
                      min-width: 50px;
                      display: flex;
                      align-items: center;
                      text-decoration: none;
                  }
                  .link:hover strong {
                      text-decoration: underline;
                  }
                  strong {
                      margin-left: 0.5em;
                      font-size: 14px;
                      font-weight: 500;
                      white-space: nowrap;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      flex: 1;
                      display: block;
                  }
                 }
  
              `}</style>
    </div>
  )
}
export default ModeratorPreview
